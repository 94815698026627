var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _vm.popupParam.opmFolderDetailId
            ? _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm revisionLayout",
                  attrs: { title: "개정", topClass: "topcolor-orange" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-1" },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: _vm.editable && _vm.isRevision,
                                  editable: _vm.editable && _vm.isRevision,
                                  label: "개정번호",
                                  name: "revisionNum",
                                },
                                model: {
                                  value: _vm.folderDetail.revisionNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.folderDetail,
                                      "revisionNum",
                                      $$v
                                    )
                                  },
                                  expression: "folderDetail.revisionNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "개정일시",
                                  name: "regDtStr",
                                },
                                model: {
                                  value: _vm.folderDetail.regDtStr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.folderDetail, "regDtStr", $$v)
                                  },
                                  expression: "folderDetail.regDtStr",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-1" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "개정자",
                                  name: "regUserName",
                                },
                                model: {
                                  value: _vm.folderDetail.regUserName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.folderDetail,
                                      "regUserName",
                                      $$v
                                    )
                                  },
                                  expression: "folderDetail.regUserName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: _vm.editable && _vm.isRevision,
                                  editable: _vm.editable && _vm.isRevision,
                                  label: "개정사유",
                                  name: "revisionContent",
                                },
                                model: {
                                  value: _vm.folderDetail.revisionContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.folderDetail,
                                      "revisionContent",
                                      $$v
                                    )
                                  },
                                  expression: "folderDetail.revisionContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "공정안전보고서 기본정보" },
            },
            [
              _c(
                "template",
                { slot: "card-select" },
                [
                  _vm.popupParam.opmFolderDetailId
                    ? _c("c-select", {
                        attrs: {
                          editable: !_vm.isRevision,
                          comboItems: _vm.gridrev.data,
                          type: "custom",
                          typetext: "개정이력",
                          itemText: "revisionNum",
                          itemValue: "opmFolderDetailId",
                          name: "selectedOpmFolderDetailId",
                          label: "",
                        },
                        on: { input: _vm.rowClickRev },
                        model: {
                          value: _vm.selectedOpmFolderDetailId,
                          callback: function ($$v) {
                            _vm.selectedOpmFolderDetailId = $$v
                          },
                          expression: "selectedOpmFolderDetailId",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              _vm.popupParam.opmFolderDetailId &&
                              !_vm.isRevision &&
                              _vm.isEnalbed,
                            expression:
                              "(editable && popupParam.opmFolderDetailId && !isRevision) && isEnalbed",
                          },
                        ],
                        attrs: { label: "개정", icon: "restart_alt" },
                        on: { btnClicked: _vm.setRevision },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              _vm.popupParam.opmFolderDetailId &&
                              _vm.isRevision &&
                              _vm.isEnalbed,
                            expression:
                              "(editable && popupParam.opmFolderDetailId && isRevision) && isEnalbed",
                          },
                        ],
                        attrs: { label: "개정취소", icon: "restart_alt" },
                        on: { btnClicked: _vm.cancelRevision },
                      }),
                      _vm.editable &&
                      _vm.popupParam.opmFolderDetailId &&
                      _vm.isEnalbed
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.deleteData },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.folderDetail,
                              mappingType: _vm.saveType,
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveFolderDetail,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        disabled: _vm.isRevision,
                        editable: _vm.editable,
                        label: "절차서 제목",
                        name: "folderDetailName",
                      },
                      model: {
                        value: _vm.folderDetail.folderDetailName,
                        callback: function ($$v) {
                          _vm.$set(_vm.folderDetail, "folderDetailName", $$v)
                        },
                        expression: "folderDetail.folderDetailName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        label: "기준일",
                        disabled: _vm.isRevision,
                        editable: _vm.editable,
                        name: "standardDate",
                      },
                      model: {
                        value: _vm.folderDetail.standardDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.folderDetail, "standardDate", $$v)
                        },
                        expression: "folderDetail.standardDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.isRevision,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.folderDetail.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.folderDetail, "plantCd", $$v)
                        },
                        expression: "folderDetail.plantCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { "padding-top": "20px !important" } },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable,
                  label: "절차서 파일",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }